<template>
    <TerminatedModal
        v-model:id="terminatedId"
        :api="apiJCProductCheckOrderTerminated"
        @success="getTabelData"
    />
    <div class="container">
        <DetailDrawer v-model:detailId="detailId" />
        <a-tabs v-model:activeKey="status" @change="onChangeTab">
            <a-tab-pane
                v-for="item in statusCodeAndNames"
                :key="item.value"
                :tab="item.valueZh + '检验单'"
            ></a-tab-pane>
        </a-tabs>
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1500 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'orderStartProductCount'">
                        <span
                            :style="{
                                color:
                                    record.orderStartProductCount == record.quantity
                                        ? '#389e0d'
                                        : '#d46b08',
                            }"
                        >
                            {{ record.orderStartProductCount }}
                        </span>
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <a
                                @click="terminatedId = record.id"
                                v-perm="'btn:jc-completed-product-check-list-terminate'"
                                v-show="status == 'WAIT_START' || status == 'IN_PROGRESS'"
                            >
                                终止
                            </a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button type="primary" @click="handleExport">导出报表</a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import DetailDrawer from "./detailDrawer";
import { showMessage, jsonToUrlParams } from "@/utils/common";
import TerminatedModal from "../../TerminatedModal";
import { baseURL } from "@/utils/config";
import { useStore } from "vuex";
import {
    apiJCProductCheckOrderList,
    apiJCProductCheckOrderSearchIndexData,
    apiJCProductCheckOrderTerminated,
} from "@/api/qualityCheck";
export default defineComponent({
    components: {
        FilterBlock,
        TerminatedModal,
        DetailDrawer,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "检验单号",
                dataIndex: "code",
            },
            {
                title: "工单号",
                dataIndex: "workOrderCode",
            },
            {
                title: "项目编码",
                dataIndex: "projectCode",
            },
            {
                title: "项目名称",
                dataIndex: "projectName",
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "规格型号",
                dataIndex: "spec",
            },
            {
                title: "集成编号",
                dataIndex: "containerNo",
            },
            {
                title: "质检方案",
                dataIndex: "qcPlanName",
            },
            {
                title: "创建时间",
                dataIndex: "createdTime",
            },
            {
                title: "创建人",
                dataIndex: "creatorDisplayName",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 120,
            },
        ];

        const { getters } = useStore();

        const state = reactive({
            status: "",
            upDateId: null,
            batchIssueRow: false,
            statusCodeAndNames: [],
            detailId: null,
            issueId: null,
            terminatedId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiJCProductCheckOrderList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: state.status,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const onChangeTab = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const eventUpdateOk = () => {
            getTabelData();
        };

        const getIndexData = async () => {
            let res = await apiJCProductCheckOrderSearchIndexData();
            if (res.status === "SUCCESS") {
                const { statusCandidate } = res?.data;
                state.statusCodeAndNames = statusCandidate;
                if (statusCandidate?.length) {
                    state.status = statusCandidate[0]?.value;
                    getTabelData();
                }
            }
        };

        getIndexData();

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        const handleExport = () => {
            let hasValue = false;
            if (state.params) {
                Object.values(state.params).find(item => {
                    if (typeof item == "string") item = item.trim();
                    if (item != undefined && item != "") {
                        hasValue = true;
                        return item;
                    }
                });
            }
            if (!hasValue) return showMessage("info", "请至少选择一个查询条件");
            let url = `${baseURL}/web/jc-product-quality-check-list/export-list`;
            url =
                url +
                jsonToUrlParams({
                    ...state.params,
                    "mes-token": getters["user/token"],
                });
            window.open(url);
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            eventUpdateOk,
            onChangeTab,
            handleOpenDetail,
            handleExport,
            getTabelData,
            apiJCProductCheckOrderTerminated,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
