<template>
    <a-table
        :columns="columns"
        :data-source="
            data?.map(item => ({
                ...item,
                ...item.basQcPlanItem?.basQcItem,
                testingDetails: item?.basQcPlanItem?.testingDetails,
            }))
        "
        bordered
        size="small"
        :pagination="false"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'testResult'">
                <span
                    :style="{
                        color: record?.testResult == 'PASS' ? '#2BCE1D' : '#ff4d4f',
                    }"
                >
                    {{ record?.testResult }}
                </span>
            </template>
            <template v-if="column.dataIndex === 'checkDuration'">
                {{ record.checkDuration ?? "0" }} h
            </template>
            <template v-if="column.dataIndex === 'checkers'">
                {{ record.checkers?.map(item => item.displayName)?.join("，") }}
            </template>
            <template v-if="column.dataIndex === 'resultList'">
                <div>
                    <a-table
                        style="margin: 0"
                        :dataSource="record.resultValueInfos"
                        size="small"
                        :pagination="false"
                        :columns="resultListColumns"
                    />
                </div>
            </template>
            <template v-if="column.dataIndex === 'pictureIds'">
                <a
                    v-if="record?.pictureIds?.length"
                    @click="handleViewPic(record.id, record?.pictureIds)"
                >
                    查看({{ record?.pictureIds?.length }})
                </a>
                <span v-else style="color: rgba(0, 0, 0, 0.3)">暂无图片</span>
                <div style="display: none" v-if="previewId == record?.id">
                    <a-image-preview-group
                        :preview="{
                            visible: Boolean(previewId == record?.id),
                            onVisibleChange: vis => (previewId = null),
                        }"
                    >
                        <a-image
                            v-for="item in record?.pictureIds"
                            :key="item"
                            :src="`${fileURL}/download/${item}`"
                        />
                    </a-image-preview-group>
                </div>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { fileURL } from "@/utils/config";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "质检项编码",
        dataIndex: "code",
    },
    {
        title: "质检项名称",
        dataIndex: "name",
    },
    {
        title: "质检项属性",
        dataIndex: "typeZh",
    },
    {
        title: "结果值",
        dataIndex: "resultList",
    },
    {
        title: "结果判定",
        dataIndex: "testResult",
    },
    {
        title: "图片",
        dataIndex: "pictureIds",
    },
];
export default defineComponent({
    props: {
        data: {
            default: [],
        },
    },

    setup() {
        const state = reactive({
            previewId: null,
        });

        const handleViewPic = (id, pics) => {
            if (!pics.length) return showMessage("info", "暂无图片");
            state.previewId = id;
        };

        return {
            ...toRefs(state),
            columns,
            fileURL,
            handleViewPic,
            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "label",
                },
                {
                    title: "结果值",
                    dataIndex: "resultValue",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
        };
    },
});
</script>
