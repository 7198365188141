<template>
    <a-descriptions title="">
        <a-descriptions-item label="质检项编码">
            {{ planInfo?.code }}
        </a-descriptions-item>

        <a-descriptions-item label="质检方案名称">
            {{ planInfo?.name }}
        </a-descriptions-item>

        <a-descriptions-item label="质检方案类别">
            {{ planInfo?.categoryName }}
        </a-descriptions-item>

        <a-descriptions-item label="附件">
            <div class="filebox">
                <a v-for="item in planInfo?.files" :href="`${fileURL}/download/${item?.id}`">
                    {{ item?.fileName }}
                </a>
            </div>
        </a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="质检项列表"></a-descriptions>
    <a-table
        :columns="columns"
        :data-source="
            planInfo?.items?.map(item => ({
                ...item,
                ...item?.basQcItem,
                testingDetails: item?.testingDetails,
            }))
        "
        bordered
        size="small"
        :pagination="false"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'standardWorkingHours'">
                {{ record?.standardWorkingHours?.workingHours }}
                {{ record?.standardWorkingHours?.unit }}
            </template>
            <template v-if="column.dataIndex === 'resultList'">
                <div>
                    <a-table
                        style="margin: 0"
                        :dataSource="record.testingDetails"
                        size="small"
                        :pagination="false"
                        :columns="resultListColumns"
                    />
                </div>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent } from "vue";
import { fileURL } from "@/utils/config";

const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "质检项编码",
        dataIndex: "code",
    },
    {
        title: "质检项名称",
        dataIndex: "name",
    },
    {
        title: "质检项属性",
        dataIndex: "typeZh",
    },
    {
        title: "检验标准",
        dataIndex: "testingStandards",
    },
    {
        title: "标准工时",
        dataIndex: "standardWorkingHours",
    },
    {
        title: "结果值列表",
        dataIndex: "resultList",
    },
];
export default defineComponent({
    props: {
        planInfo: {
            default: null,
        },
    },

    setup(props) {
        return {
            columns,
            fileURL,
            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "label",
                },
                {
                    title: "标准值",
                    dataIndex: "standardValue",
                },
                {
                    title: "上限",
                    dataIndex: "upperLimit",
                },
                {
                    title: "下限",
                    dataIndex: "lowerLimit",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
